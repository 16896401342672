import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useLocation } from 'react-router-dom';
import { Persona, isPersona } from './PersonaType';
import HomePage from './HomePage'; 
import PersonaPage from './PersonaPage'; 
import Gallery from './Gallery';
import NavBar from './Navbar';
import Blood from './Blood';
import OthersPage from './Others';
import SearchAndSort  from './Search';
import NewsList from './News';
import "./App.css";

import Pusher from 'pusher-js';
import PusherTypes from 'pusher-js'; 

import ethers from 'ethers';
import { BrowserProvider, FixedNumber, formatEther, JsonRpcProvider, Contract, parseEther } from 'ethers';
import { EventLogProvider, EventLog, useEventLog } from './EventLog'; 

import { testL1ns } from './L1names';

import Countdown from './Countdown';
import PersonaArtifact from './contracts/Persona.json';
import BloodArtifact from './contracts/Blood.json';
import BidArtifact from './contracts/Bid.json';
import WL1Artifact from './contracts/WL1.json';
import HiveArtifact from './contracts/HiveSpace.json';

const personaAbi = PersonaArtifact.abi;
const bloodAbi = BloodArtifact.abi;
const bidAbi =  BidArtifact.abi;
const wl1Abi = WL1Artifact.abi;
const hiveAbi = HiveArtifact.abi;

//const personaAddress ="0x39Ad0b6fa13E3a348CB04817902EbB8b218113cf";
//0x47EF4452838EcDc8F010FDD616C7ECFd9fbcd526"; //0x9fe46736679d2d9a65f0992f2272de9f3c7fa6e0";
//const bloodAddress = 	"0xbB730DD2f014a713159B42e97f1E8Cc4F00fb15E";
//"0xB3a6288d3524Cf8A2E53c184190276fEE793E623"; //0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512";
//const bloodAddress = "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512";

const bloodAddress = process.env.REACT_APP_BLOOD_CONTRACT || "";
const personaAddress = process.env.REACT_APP_PERSONA_CONTRACT || "";
const nftAddress = process.env.REACT_APP_PERSONAS_CONTRACT || ""; 
const bidAddress = process.env.REACT_APP_BID_CONTRACT || "";
const WL1Address = process.env.REACT_APP_WL1_CONTRACT || "";
const hiveAddress = process.env.REACT_APP_HIVE_CONTRACT || ""; //TODO: Update blood/persona based on HiveAddr.


declare global {
  interface Window {
    ethereum: any;
  }
}

function isActive(objects: any[]): objects is Persona[] {
  if (!Array.isArray(objects)) {
    console.log("Expected an array, but received:", objects);
    return false;
  }
  return objects.every(isPersona);
}



const App: React.FC = () => {
  const [personas, setPersonas] = useState<Persona[]>(Array(2049).fill(null));
  const [photos, setPhotos] = useState<Persona[]>([]);
  const [account, setAccount] = useState<string | null>(null);
  const [ethBalance, setEthBalance] = useState<string | null>(null);
  const [bloodBalance, setBloodBalance] = useState<React.ReactNode| null>(null);
  const [bloodBalanceEth, setBloodBalanceEth] = useState<number>(0);
  const [blood, setBlood] = useState<Contract | null>(null);
  const [persona, setPersona] = useState<Contract | null>(null);
  const [hive, setHive] = useState<Contract | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [provider, setProviderState] = useState<BrowserProvider | null>(null);
  const [showBloodButton, setShowBloodButton] = useState(true);
  const [blockNum, setBlockNum] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      await testL1ns();
    }

    fetchData();
  }, []);


  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher('5ee8e667f23271bbb186', {
      cluster: 'us2',
    });

    // if env is dev, subscribe to the 'persona-dev' channel,
    // otherwise, subscribe to persona.


    // let channel: channel_Channel;
    //    let channel: PusherTypes.Channel;
    let channel_name: string;
    if (process.env.NODE_ENV === 'development') {
      channel_name = "persona-dev";
      console.log("Dev Mode: using persona-dev for pusher");
    } else {
      channel_name = "persona";
    }

    const channel = pusher.subscribe(channel_name);

    channel.bind('message', function(data: any) {
      if (isPersona(data)) {
        console.log("Updating Persona", data.id);
        updatePersona(data);
      } else if (isActive(data)){
        // we've got an active datagram.
        console.log("Got active data", data) ;
        setPhotos(data);
      } else {
        console.log("Unknown data", data);
      }
    });


    // Cleanup Pusher subscription on component unmount
    return () => {
      pusher.unsubscribe('persona');
    };
  }, []);

  const declinedKey: string = `${bloodAddress}_declined`;
  const trackingKey: string = `${bloodAddress}_tracking`;

  const setProvider = () => {
    if (window.ethereum) {
      const newProvider = new BrowserProvider(window.ethereum);
      setProviderState(newProvider);
    }
  };

  async function addToken(): Promise<void> {
    const tokenAddress = bloodAddress;
    const tokenImage: string = 'http://placekitten.com/200/300';

    // Check if the user has declined or is already tracking
    if (localStorage.getItem(declinedKey)) {
      console.log("Declined this token. Won't ask again.");
      return;
    } 
    if (localStorage.getItem(trackingKey)) {
      console.log('Already tracking this token.');
      return;
    }

    try {
      const wasAdded: boolean = await (window as any).ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: 'BLOOD',
            decimals: 18,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        //console.log('Thanks for your interest!');
        localStorage.setItem(trackingKey, 'true');
      } else {
        //console.log('Your loss!');
        localStorage.setItem(declinedKey, 'true');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const connectWallet = async () => {
    try {
      const signer = await provider?.getSigner();
      const accounts = await provider?.listAccounts();
    } catch (error: any) { }
  };

  const updatePersona = (newPersona: Persona) => {
    setPersonas(prevPersonas => {
      console.log("prev personas", prevPersonas);
      const updatedPersonas = [...prevPersonas];
      console.log("updated personas", updatedPersonas);
      updatedPersonas[newPersona.id - 1] = newPersona;
      console.log("updated personas after update", updatedPersonas);
      return updatedPersonas;
    });
  };

  const checkConnection = async () => {
    if (provider) {
      const accounts = await provider.listAccounts();
      setIsConnected(accounts && accounts.length > 0);
    }
  };

  const handleAccountsChanged = async (accounts: string[]) => {
    console.log("handleAccountsChanged");
    try {
      console.log("handleAccountsChanged inside try");
      setAccount(accounts[0]);
      const signer = await provider?.getSigner();
      const personaContract= new Contract(personaAddress, personaAbi, signer);
      setPersona(personaContract);

      const hiveContract = new Contract(hiveAddress, personaAbi, signer);
      setHive(hiveContract);
    } catch (error: any) {
      console.log("Error", error)}
  };

  // Initialize
  const setup = async () => {
    try {
      const signer = await provider?.getSigner();
      const personaContract = new Contract(personaAddress, personaAbi, signer);
      setPersona(personaContract);
      const accounts = await provider?.listAccounts();
      if (accounts && accounts.length > 0) {
        setAccount(accounts[0].address);
      }
      const hiveContract = new Contract(hiveAddress, personaAbi, signer);
      setHive(hiveContract);
    } catch (error: any) { }
  };

  // Set up the block listener to update the block number on each new block
  const blockListener = (newBlockNumber: number) => {
    console.log("New Block", newBlockNumber);
    setBlockNum(newBlockNumber);
  }

  useEffect(() => {
    console.log("initial useEffect");
    setProvider();

    checkConnection();

    if (!window.ethereum) { return }


    // Listen for account changes
    window.ethereum.on('accountsChanged', (accounts: string[]) => {
      setIsConnected(accounts && accounts.length > 0);
      handleAccountsChanged(accounts);
    });

    setup();

    if (localStorage.getItem(declinedKey) || localStorage.getItem(trackingKey)) {
      setShowBloodButton(false);
    }

    fetch('/active')
      .then(response => response.json())
      .then(data => {
        setPhotos(data);
      });

    fetch('/loadNFTs')
      .then(response => response.json())
      .then(data => {
        console.log("Loaded NFTs", data);
        setPersonas(data);
      });
    // Cleanup on unmount
    return () => {
      window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, []);

  const abbreviateAddress = (address: string | null) => {
    // Return the address with the middle part replaced by '...'
    return address ? ( <span title={address}>
      {address.slice(2, 6)}&hellip;{address.slice(-4)}
      </span>) : (<span>Connect an L1 wallet</span>);
  };

  useEffect(() => {
    console.log('provider changed 2');
    if (!provider) { return}
    // Fetch the current block number and set it
    const fetchBlockNumber = async () => {
      const currentBlockNumber = await provider!.getBlockNumber();
      setBlockNum(currentBlockNumber);
    }

    // Call the fetch function and set up the listener
    fetchBlockNumber();
    provider!.on('block', blockListener);
    (async () => {
      try {
        console.log('provider changed 2 inside try');

        const accounts = await provider.listAccounts();
        if (accounts && accounts.length > 0) {
          setAccount(accounts[0].address);
        }

        const signer = await provider.getSigner();
        const bloodContract = await new Contract(bloodAddress, bloodAbi, signer);
        setBlood(bloodContract);

        const persona = await new Contract(personaAddress, personaAbi, signer);
        setPersona(persona);

      const hiveContract = new Contract(hiveAddress, personaAbi, signer);
        await setHive(hiveContract);

        if (hive) {
        hive.on('RealSurrection', (id) => {
          console.log(`${id} has been RealSurrected!`);
        });

        hive.on('Infusion', (arg1, arg2, arg3, arg4) => {
          console.log(`Infusion event with args: ${arg1}, ${arg2}, ${arg3}, ${arg4}`);
        });

        hive.on('Harvested', (id, addr) => {
          console.log(`Harvested event with ID: ${id} and address: ${addr}`);
        });

        hive.on('Found', (id) => {
          console.log(`Persona ${id} has been found inside the Hive.`);
        });
        }
      } catch (error: any) { return }

    })();
  }, [provider]);


  useEffect(() => {
    console.log("provider,account,blood");
    if (provider && account && blood) {
      fetchBloodBalance();
    }
  }, [provider, account, blood, blockNum]);

  const finalize = async(photoId: string) => {
    try {
      await persona?.finalizeInfusion(photoId);
    } catch(error: any) {
      console.error(`Error finalizing Infusion and RealSurrecting: ${error.reason}`);
    }
  };

  const harvest = async(personaId: string) => {
    try {
      await hive?.harvest(personaId);
    } catch(error: any) {
      console.error(`Error Harvesting. How Could You??: ${error}`);
    }
  };

  const purify = async(personaId: string) => {
    // amount is the totalInfused value * 2
    try {
      const amount = BigInt(personas[Number(personaId)].totalInfused)*BigInt(2)+BigInt(2000);

      console.log(amount);
      await hive?.purify(personaId, {value: amount.toString()});
    } catch(error: any) {
      console.error(`Error Purifying. ${error}`);
    }
  };

  /*
  const collectivistName = async(personaId: string) => {
    let amount: BigInt; 

    if (personas[Number(personaId)].purified) {
      amount = personas[Number(personaId)].totalInfused/BigInt(100) + BigInt(1);
    } else {
      amount = personas[Number(personaId)].totalInfused*BigInt(3)/BigInt(100) + BigInt(1);
    } 

    try {
      await hive?.collectivistName(personaId, {value: amount.ToString()});
    } catch(error: any) {
      console.error(`Error Collectivist Naming. ${error}`);
    }
  }
   */

  const increaseGasLimit = (estimatedGasLimit: bigint) => {
    return estimatedGasLimit* BigInt(130) / BigInt(100) // increase by 30%
  }

  const inject = async (photoId: string, amount: BigInt) => {
    if (hive) {
      try {
      const gasEst = await hive?.infuse.estimateGas(photoId, {value: amount});
      const tx = await hive?.infuse(photoId,{value: amount, gasLimit: increaseGasLimit(gasEst)});
    } catch(error: any) {
      console.error(`Error infusing: ${error.reason}`);
    }
    }
  };

  useEffect(() => {
    console.log("provider+account change 202");
    if (provider && account && blockNum != 0) {
      fetchEthBalance();
      fetchBloodBalance();
    }
  }, [provider, account, blockNum]);



  const fetchEthBalance = async () => {
    if (provider && account) {
      const balance = await provider.getBalance(account);
      setEthBalance(parseFloat(formatEther(balance)).toFixed(2));
    }
  };

  const fetchBloodBalance = async () => {
    console.log("provider", provider);
    console.log("account", account);
    console.log("blood", blood);
    if (provider && account && blood) {
      let balance: number;
      try {
        balance = await blood.balanceOf(account); 
      } catch (error) {
        console.log("Error fetching balance", error);
        return;
      }
      //const balance = await blood.balanceOf(account);
      const bloodVal = parseFloat(formatEther(balance)).toFixed(2);
      const bloodF = parseFloat(formatEther(balance));

      setBloodBalanceEth(parseFloat(formatEther(balance)));

      if (bloodF == 0) {
        setBloodBalance(<span>Pure</span>);
        return;
      } else if (bloodF < 100 ) {
        setBloodBalance(<span>Pure?</span>);
        return;
      } else if (bloodF < 250) {
        setBloodBalance(<span>Tainted</span>);
        return;
      } else if (bloodF < 1000) {
        setBloodBalance(
          <>
          <span>You have blood on your hands.. </span>
          <span>{bloodVal}</span>
          </>
        );
        return;
      } else {
        setBloodBalance(
          <Link to="/blood">{bloodVal}</Link>
        );
      }
    }
  };

  return (
    <Router>
      <EventLogProvider>
        <NavBar account={account} ethBalance={ethBalance} bloodBalance={bloodBalance} connectWallet={connectWallet} abbreviateAddress={abbreviateAddress} blockNum={blockNum} />
        <div>
          <Routes>
            <Route path="/" element={<HomePage inject={inject} provider={provider} persona={persona} blood={blood} account={account} personas={personas} photos={photos} blockNum={blockNum} purify={purify} hive={hive} />} />
            <Route path="/persona/:id" element={<PersonaPage account={account!} harvest={harvest} finalize={finalize} inject={inject} parseEther={parseEther} persona={persona!} blockNum={blockNum} bloodBalance={bloodBalanceEth} propId={null} purify={purify} hiveAddress={hiveAddress} nftAddress={nftAddress}/>} />
            <Route path="/collective" element={<Gallery account={account!} finalize={finalize} inject={inject} parseEther={parseEther} harvest={harvest} persona={persona!} blockNum={blockNum} bloodBalance={bloodBalanceEth} personas={personas} purify={purify} hiveAddress={hiveAddress} nftAddress={nftAddress} />} /> 
            <Route path="/blood" element={<Blood address={account!} blood={blood!} provider={provider!} bloodAddress={bloodAddress} blockNum={blockNum} />} />
            <Route path="/collectives/:addr" element={<OthersPage personas={personas} blockNum={blockNum} />} />
            <Route path="/collectives" element={<OthersPage personas={personas} blockNum={blockNum} />} />
            <Route path="/registry" element={<SearchAndSort personas={personas} />} />
            <Route path="/news" element={<NewsList />} />
          </Routes>
        </div>
        <p className="cc">
          <a href="https://personacollective.ai">Persona Collective</a> is Copyright 2023, by <span>Peter Vessenes</span>. All content and images are licensed under &nbsp;
          <a href="http://creativecommons.org/licenses/by-sa/4.0/?ref=chooser-v1" target="_blank" rel="noopener noreferrer" style={{display: 'inline-block'}}>
            CC BY-SA 4.0
    <img style={{height: '22px', marginLeft: '3px', verticalAlign: 'text-bottom'}} src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" alt="CC"/>
    <img style={{height: '22px', marginLeft: '3px', verticalAlign: 'text-bottom'}} src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" alt="BY"/>
    <img style={{height: '22px', marginLeft: '3px', verticalAlign: 'text-bottom'}} src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1" alt="SA"/>
  </a>
</p>
</EventLogProvider>
  </Router>
  );
};

export default App;

