import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BrowserProvider, FixedNumber, formatEther, JsonRpcProvider, Contract, parseEther } from 'ethers';

import { InfuseButton, RealSurrectButton, HarvestButton, TimeRemainingTicker, PurifyButton, AddToWalletButton, NameButton } from './Buttons';
import { Persona, isPersona } from './PersonaType';
import  L1Address  from './L1Address';
import { GetBidByToken, PlaceBid, CancelBid, SetAllowance, AcceptBidButton } from './Bids';
type PersonaPageProps = {
  finalize: Function,
  inject: Function,
  parseEther: Function,
  account: string,
  persona: Contract,
  harvest: Function,
  blockNum: number,
  bloodBalance: number | null,
  propId: string | null,
  purify: Function,
  hiveAddress: string,
  nftAddress: string,
};


const PersonaPage: React.FC<PersonaPageProps> = ({finalize, inject, parseEther, account, persona, harvest, blockNum, bloodBalance, propId, purify, hiveAddress, nftAddress}) => {
  const [personaData, setPersonaData] = useState<Persona | null>(null);
  const { id: routeId } = useParams();


  //TODO: Don't download! use personas
  useEffect(() => {
    const id = propId !== null ? propId : routeId;

    const fetchData = async () => {
      try {
        console.log(`Fetching data for ID: ${id}`);
        const response = await fetch(`/api/${id}.json`);

        if (!response.ok) {
          console.error(`HTTP Error: ${response.status}`);
          return;
        }
        const data = await response.json();
        //console.log('Received data:', data);
        setPersonaData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [propId, routeId, blockNum]);

  //        (personaData.harvested ? <div>Harvested by You. For Shame.</div> : null)

  return (
    <div>
      <div className="overlay-container">
        {personaData && personaData.harvested ? (
        <div className="overlay-text">HARVESTED. SHAME.</div>
        ) : null}
      </div>
      {personaData ? (
      <div>
        <h1>{personaData.name}</h1>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <img 
            width={"90%"} 
            className={personaData.purified ? "purified" : ""} 
            src={`/api/${personaData.id}.png${personaData.harvested ? "?" : ""}`} 
            alt={personaData.name} 
          />
            </span>
            <span>
              <h2>Hiver #{personaData.id} Statistics</h2>
              <p>{personaData.description}</p>
              <p>Profession: {personaData.attributes.profession.value}</p>
              <p>Tribe: {personaData.attributes.tribe.value}</p>
              <p>Strength: {personaData.attributes.strength.value}</p>
              <p>Intelligence: {personaData.attributes.intelligence.value}</p>
              <p>Charisma: {personaData.attributes.charisma.value}</p>
              <p>Luck: {personaData.attributes.luck.value}</p>
              <p>Pronoun: {personaData.attributes.pronoun.value}</p>
              <p>Expression: {personaData.attributes.expression.value}</p>
              <p>Wake Date: {new Date(personaData.wakeDate * 1000).toLocaleString()}</p>
              <p>Total Infused: {parseFloat(formatEther(BigInt(personaData.totalInfused))).toFixed(3)} L1</p>
              <p>Last Infusion: {parseFloat(formatEther(BigInt(personaData.lastInfusion))).toFixed(3)} L1</p>
              <p>Sponsor: <L1Address address={personaData.sponsor} blockNum={blockNum} abbreviate={false}/></p>
              {
              personaData.collector != "0x0000000000000000000000000000000000000000" && (
              <p>
                Collector: <L1Address address={personaData.collector} blockNum={blockNum} abbreviate={false}/> 
              </p>
              )
              }
              <div className="button-container">
                <span><TimeRemainingTicker persona={personaData} /></span>
                <span><InfuseButton persona={personaData} inject={inject} blockNum={blockNum} /></span>
                <span><RealSurrectButton persona={personaData} finalize={finalize} /></span>
                <span><PurifyButton persona={personaData} purify={purify} account={account} /></span>
                <span><HarvestButton persona={personaData} account={account} harvest={harvest} bloodBalance={bloodBalance}/></span>
                { personaData.collector === account ? <span><AddToWalletButton tokenId={personaData.id.toString()} nftAddress={nftAddress} /></span> : null } 
              </div>
              <div className="button-container">
              </div>
            </span>
          </div>
        </div>
        ) : (
        <div>Loading...</div>
        )}
      </div>
  );
};

export default PersonaPage;

