import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { BrowserProvider, FixedNumber, formatEther, JsonRpcProvider, Contract, parseEther } from 'ethers';

import PersonaPage from './PersonaPage';
import { Persona } from './PersonaType';

type AttributeType = {
  trait_type: string;
  value: string | number;
  display_type?: string;
};



type GalleryProps = {
  account: string,
  finalize: Function,
  inject: Function,
  parseEther: Function,
  harvest: Function;
  persona: Contract;
  blockNum: number;
  bloodBalance: number;
  personas: Persona[];
  purify: Function;
  hiveAddress: string;
  nftAddress: string;
};

const Gallery: React.FC<GalleryProps> = ({ account, finalize, inject, parseEther, harvest, persona, blockNum, bloodBalance, personas, purify, hiveAddress, nftAddress }) => {
  const [owned, setOwned] = useState<Persona[]>([]);
  const [hoveredPhoto, setHoveredPhoto] = useState<Persona | null>(null);


  useEffect(() => {
    console.log("Parsing Gallery personas", personas);
    let tempOwned: Persona[] = [];
    personas.forEach(persona => {
       if (!persona || !persona.sponsor) return; 
      if (persona.sponsor != account || persona.woken == false ) { return; }
      tempOwned.push(persona);
    });

    tempOwned.sort((a, b) => a.wakeDate - b.wakeDate);
    setOwned(tempOwned)
  }, [account, blockNum, personas]);

  return (
          <div style={{ display: 'flex' }}>
            <div style={{ overflowY: 'scroll',  maxHeight: '90vh',width: '200px',  }}>
              {owned.map((photo) => (
              <div
              key={`gallery-${photo.id}`}
              onMouseEnter={() => setHoveredPhoto(photo)}
              >
              <img width="128" height="128" src={`/api/${photo.id}.png?size=128`} alt={photo.name}   style={hoveredPhoto?.id === photo.id ? {} : { filter: "grayscale(100%)" }}
            />
              </div>
              ))}
            </div>
            <div style={{ width: '100%' }}>
              {hoveredPhoto && <PersonaPage account={account!} harvest={harvest} finalize={finalize} inject={inject} parseEther={parseEther} persona={persona}  blockNum={blockNum} bloodBalance={bloodBalance} propId={hoveredPhoto.id.toString()} purify={purify} hiveAddress={hiveAddress} nftAddress={nftAddress} />}
            </div>
          </div>
        );
        };


        export default Gallery;

