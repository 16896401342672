import React, { useState } from 'react';
import _ from 'lodash';
import { Persona } from './PersonaType';
import L1Address from './L1Address';
import {formatEther} from 'ethers';
interface Props {
  personas: Persona[];
}

const SearchAndSort: React.FC<Props> = ({ personas }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKey, setSortKey] = useState<keyof Persona | string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  // Filter personas

  if (personas[0] === null) { return <></>; }
  const filteredPersonas = personas.filter(persona => {

    if ( !persona.alive || !persona.woken || persona.harvested) { return false; }
    return Object.values(persona).some(value =>
      typeof value === 'string' ? value.toLowerCase().includes(searchTerm.toLowerCase()) : false
    ) || Object.values(persona.attributes).some(attribute => 
      typeof attribute.value === 'string' ? attribute.value.toLowerCase().includes(searchTerm.toLowerCase()) : false
    );
  });

  // Sort personas
  let sortedPersonas = [...filteredPersonas];  // Create a copy

  if (sortKey) {
    // make sure filteredPersonas is longer than 0

    if ( filteredPersonas.length >0 && sortKey in filteredPersonas[0]) {
      // Sort by top-level properties
      sortedPersonas = _.orderBy(filteredPersonas, [sortKey], [sortOrder]);
    } else if (filteredPersonas.length > 0 && sortKey == "stats") {
      sortedPersonas.sort((a,b) => {
        // Sum up Strength, Charisma, Intelligence, Luck and use that to compare. 
        const aSum = a.attributes.strength.value + a.attributes.charisma.value + a.attributes.intelligence.value + a.attributes.luck.value;
        const bSum = b.attributes.strength.value + b.attributes.charisma.value + b.attributes.intelligence.value + b.attributes.luck.value;
        return sortOrder === 'asc' ? aSum - bSum : bSum - aSum;
      });
    } else if (filteredPersonas.length >0  && filteredPersonas[0].attributes && sortKey in filteredPersonas[0].attributes) {
      // Sort by nested 'attributes'
      sortedPersonas.sort((a, b) => {
        const aValue = a.attributes[sortKey]?.value || "";
        const bValue = b.attributes[sortKey]?.value || "";
        // strength, intelligence, charisma, luck, are numbers, compare numerically

        if (sortKey === 'strength' || sortKey === 'intelligence' || sortKey === 'charisma' || sortKey === 'luck') {
          return sortOrder === 'asc' ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
        }
        return sortOrder === 'asc' ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
      });
    }
  }

  return (
    <div>
      <h1>RealHuman Living Registry</h1>
      <div className="search">
        <div className="search-left">
          <input 
          type="text"
          placeholder="Search..."
          value={searchTerm}
    onChange={e => setSearchTerm(e.target.value)}
    />
          </div>
          <div className="search-right">
            <select onChange={e => setSortKey(e.target.value as keyof Persona)}>
              <option value="">Sort By</option>
              <option value="totalInfused">Total Infused</option>
              <option value="collector">Collector</option>
              <option value="sponsor">Sponsor</option>
              <option value="profession">Profession</option>
              <option value="tribe">Tribe</option>
              <option value="expression">Expression</option>
              <option value="name">Name</option>
              <option value="id">ID</option>
              <option value="stats">Stats</option>
              <option value="strength">Strength</option>
              <option value="intelligence">Intelligence</option>
              <option value="charisma">Charisma</option>
              <option value="luck">Luck</option>
            </select>
            <button onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
              Toggle Sort Order
            </button>
          </div>
        </div>
        <div className="search">
          <table id="searchtable">
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                <th>Profession</th>
                <th>Tribe</th>
                <th>Pronoun</th>
                <th>Expression</th>
                <th>Str</th>
                <th>Int</th>
                <th>Cha</th>
                <th>Lck</th>
                <th>Collector</th>
                <th>Infusion</th>
                <th>Best Offer</th>
              </tr>
            </thead>
            <tbody>
              {sortedPersonas.map(persona => (
              <tr id={`search-row-$[persona.id}`}>
                <td><a href={`/persona/${persona.id}`}><img className="greyscale" width="64" height="64" src={`/api/${persona.id}.png?size=64`} /></a></td>
                <td>{persona.id}</td>
                <td>{persona.attributes.profession.value}</td>
                <td>{persona.attributes.tribe.value}</td>
                <td>{persona.attributes.pronoun.value}</td>
                <td>{persona.attributes.expression.value}</td>
                <td>{persona.attributes.strength.value}</td>
                <td>{persona.attributes.intelligence.value}</td>
                <td>{persona.attributes.charisma.value}</td>
                <td>{persona.attributes.luck.value}</td>
                <td style={{textAlign:"center"}}><L1Address address={persona.collector} blockNum={0} abbreviate={true} /></td>
                <td>{parseFloat(formatEther(BigInt(persona.totalInfused))).toFixed(3)}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default SearchAndSort;

