// NavBar.tsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BrowserProvider, FixedNumber, formatEther, JsonRpcProvider, Contract, parseEther } from 'ethers';
import { Persona } from './PersonaType';


type NavBarProps = {
    connectWallet: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  abbreviateAddress: Function;
  account: string | null;
  bloodBalance: React.ReactNode|null;
  ethBalance: string|null;
  blockNum: number;
}

export const NavBar: React.FC<NavBarProps> = ({connectWallet, abbreviateAddress, account, bloodBalance, ethBalance, blockNum }) => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <nav>
      <ul>
        <li>
          <Link to="/" className={pathname === "/" ? "active-nav-item" : ""}>Home</Link>
        </li>
        <li>
          <Link to="/collective" className={pathname === "/collective" ? "active-nav-item" : ""}>Collective</Link>
        </li>
        <li>
          <Link to="/news" className={pathname === "/news" ? "active-nav-item" : ""}>News</Link>
        </li>
        <li>
          <Link to="/registry" className={pathname === "/registry" ? "active-nav-item" : ""}>Registry</Link>
        </li>
        {!account ? (
        <li>
          <button onClick={connectWallet}>Connect</button>
        </li>
        ) : (
        <>
        <li style={{verticalAlign:'top'}}>
          <span>Welcome RealHuman </span>
          <span style={{ whiteSpace: 'nowrap' }}>
            #{abbreviateAddress(account)}
          </span>
          <span>&nbsp;</span>
          <span>{ethBalance}L1</span>
        </li>
        <li>
          <span>{bloodBalance}</span>
        </li>
    </>
    )}
  </ul>
</nav>
  );
}

export default NavBar;

