// L1Address.tsx

import { useEffect, useState } from 'react';
import { namehash } from 'ethers'; 
import * as nameHash from '@ensdomains/eth-ens-namehash';
import { JsonRpcProvider, Contract } from 'ethers';
import abi from './PublicResolver.json';

interface Props {
  address: string;
  blockNum: number;
  abbreviate: boolean | null;
}

const ID_PROVIDER = "https://rpc-betanet.lamina1.com:443/ext/bc/2CPW4Bj4YGPuchkzo3tNgryL2JNUGDxpHQtRLUznQajnY96g5A/rpc";
const ID_RESOLVER = "0xDcFEc218bAeD9f7C5c370a83c921A4Dd690B1B3C"; 

const provider = new JsonRpcProvider(ID_PROVIDER);
const resolver = new Contract(ID_RESOLVER, abi.abi, provider);

  const abbreviateAddress = (address: string | null) => {
    // Return the address with the middle part replaced by '...'
    return address ? ( <span title={address}>
      {address.slice(2, 6)}&hellip;{address.slice(-4)}
      </span>) : (<span>Connect an L1 wallet</span>);
  };

export default function L1Address({ address, blockNum, abbreviate }: Props) {

  const [name, setName] = useState<string | undefined>();

  useEffect(() => {

    const fetchName = async () => {
      const normalizedAddr = nameHash.normalize(address.substring(2) + ".addr.reverse");
      const node = namehash(normalizedAddr);
      const ensName = await resolver.name(node);
      if (ensName != "") { setName(ensName);}
    }
    
    fetchName();

  }, [address, blockNum]);

  if (abbreviate && !name) {
    return <>{abbreviateAddress(address)}</>;
  }

  return <>{name ?? address}</>;

}

