import React, { useState, useEffect} from 'react';
import { BrowserProvider, FixedNumber, formatEther, JsonRpcProvider, Contract, parseEther } from 'ethers';
import { ethers } from 'ethers';
import './Blood.css';
import { useNavigate } from 'react-router-dom';

interface BloodProps {
  provider: BrowserProvider; // Inject web3 provider
  blood: Contract; // Blood contract
  bloodAddress: string;
  address: string; // Address of the Blood contract
  blockNum: number; 
}

const Blood: React.FC<BloodProps> = ({ provider, blood, bloodAddress, address, blockNum }) => {
  const [balance, setBalance] = useState<string | number>(0);
  const [totalSupply, setTotalSupply] = useState<string>("");
  const [ethBalance, setEthBalance] = useState<string>("");
  const [injectValue, setInjectValue] = useState<string>('');
  const [burnValue, setBurnValue] = useState<string>('');
  const [injectEstimate, setInjectEstimate] = useState<number>(0);
  const [burnEstimate, setBurnEstimate] = useState<number>(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (balance < 250) {
      const timer = setTimeout(() => {
        navigate('/');
      }, 5000); // 5 seconds

      return () => clearTimeout(timer); // Cleanup timer if component unmounts
    }
  }, [balance]);

  useEffect(() => {
    // Fetch initial data
    const fetchData = async () => {
      const balance = await blood.balanceOf(address);
      const totalSupply = await blood.totalSupply();
      const ethBalance = await provider.getBalance(bloodAddress);

      // setBalance(parseFloat(formatEther(balance)));
      // round to 2 decimal places
      setBalance(parseFloat(formatEther(balance)).toFixed(2));
      setTotalSupply(parseFloat(formatEther(totalSupply)).toFixed(2));
      setEthBalance(parseFloat(formatEther(ethBalance)).toFixed(2));
    };

    fetchData();
  }, [address, blood, provider]);


  const updateInjectEstimate = async () => {
    if (injectValue && !isNaN(parseFloat(injectValue))) {
      const estimate = await blood.mintEstimate(parseEther(injectValue), address); 
      setInjectEstimate(parseFloat(formatEther(estimate)));
    } else {
      setInjectEstimate(0);
    }
  };

  const updateBurnEstimate = async () => {
    if (burnValue && !isNaN(parseFloat(burnValue))) {
      const estimate = await blood.burnEstimate(parseEther(burnValue), address);
      setBurnEstimate(parseFloat(formatEther(estimate)));
    } else {
      setBurnEstimate(0);
    }
  };

  const handleInject = async () => {
    await blood.inject({ value: parseEther(injectValue) });

  };

  const handleBurn = async () => {
    await blood.burn(parseEther(burnValue));
  };

  return (
    <div> 
        <h1 className="bloodh1">Welcome To The BLOOD Bank! Helping Those Who Work!</h1>
      <div className="container">
      <div><img src="/blood.png" width="512" height="512" /></div>
      <div>
        {(balance < 250 ? <h1>BLOOD BANK ONLY OPEN TO REGISTERED MEMBERS</h1> :
        <>
        <p>You hold {balance} BLOOD</p>
        <p>BLOOD issued in trust for citizens: {totalSupply}</p>
        <p>The BLOOD bank is backed by: {ethBalance}L1</p>
        <h2>Blood Bank: Deposit </h2>
        <p>
          <input
          type="text"
          placeholder="L1 injection amount "
          onChange={async (e) => {
            const value = e.target.value;
            setInjectValue(value);
            if (value && !isNaN(parseFloat(value))) {
              const estimate = await blood.mintEstimate(parseEther(value), address);
              setInjectEstimate(parseFloat(formatEther(estimate)));
            } else {
              setInjectEstimate(0);
            }
          }}
        />

          <button onClick={handleInject}>Inject</button>
        </p>
        <p>Blood issuance (est): {injectEstimate}</p>

        <h2>Blood Bank: Withdraw</h2>
        <input
        type="text"
        placeholder="BLOOD Tokens (only clean tokens allowed by HiveSpace authorities)"
        onChange={async (e) => {
          const value = e.target.value;
          setBurnValue(value);
          if (value && !isNaN(parseFloat(value))) {
            const estimate = await blood.burnEstimate(parseEther(value), address);
            setBurnEstimate(parseFloat(formatEther(estimate)));
          } else {
            setBurnEstimate(0);
          }
        }}
      />

        <button onClick={handleBurn}>Burn</button>
        <p>Estimated L1: {burnEstimate}</p>
        
    </>
    )}
  </div>
    </div>
    </div>
  );
};

export default Blood;

