import React, { useEffect, useState } from 'react';

interface CountdownProps {
  endTime: number;
}

const Countdown: React.FC<CountdownProps> = ({ endTime }) => {
  const [timeLeft, setTimeLeft] = useState<string | null>(null);

  useEffect(() => {
    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 137);
    return () => clearInterval(timer);
  }, [endTime]);

  if ( endTime == 0) { return null; }

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const distance = (endTime*1000 - now)/1000;

    //console.log(now, endTime, distance);
    if (distance < 0) {
      setTimeLeft("Infusion Ended");
      return;
    }

    setTimeLeft(`${distance}s`);
  };


  return <span>{timeLeft}</span>;
};

export default Countdown;
