import React, { useState, useEffect } from 'react';
import sha256 from 'crypto-js/sha256';

const Oscar = ({ account }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    // Hash the account using sha256
    const hash = sha256(account);
    // Convert the hash to an integer
    const num = parseInt(hash, 16);
    // Use the integer to pick a random image from the sequence
    const imageNum = num % 7 + 1;
    setImage(`/oscar${imageNum}.png`);
  }, [account]); // Pass in the account prop as a dependency to trigger re-render when it changes

  return (
    <img src={image} alt="Oscar" 
    style={{
      display: 'block',
      marginLeft: 'auto',
      float: 'left',
      marginRight: '20px',
      width: '256px',
    }}
    />
  );
};

export default Oscar;
