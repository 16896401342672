import React, { useState, useEffect } from 'react';

type NewsItem = {
  headline: string;
  body: string;
  image?: string;
  link?: string;
};

const NewsItem: React.FC<NewsItem> = ({ headline, body, image, link }) => {
  return (
    <article style={{ display: 'flex', marginBottom: '20px' }}>
      {image && (
        <img
          src={image}
          alt={headline}
          style={{
            width: '256px',
            height: '256px',
            marginRight: '20px',
            objectFit: 'cover',
            flexShrink: 0,
          }}
        />
      )}
      <div style={{ flex: 1 }}>
        <h3 style={{ margin: '0 0 10px 0' }}>{headline}</h3>
        <p>{body} 
        { link && (
        <><br /><br /><a className="newsLink" href={link}  target="_blank" rel="noopener noreferrer">Read More..</a></>
        )}
      </p>
      </div>
    </article>
  );
};

const NewsList: React.FC = () => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const response = await fetch('/news_latest.json');
        const data = await response.json();
        setNewsItems(data);
      } catch (error) {
        console.error('Error fetching news items:', error);
      }
    };

    fetchNewsItems();
  }, []);

  return (
    <div>
      <h1>Hive City news</h1>
      <div>
      {newsItems.map((item, index) => (
        <NewsItem key={index} {...item} />
      ))}
      </div>
    </div>
  );
};

export default NewsList;
