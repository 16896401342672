import React from 'react';
import Oscar from './Oscar.js';

const Help = ({ account, isVisible, toggleHelpVisibility }) => {
  return isVisible && (
    <div>
    <h2 align='center'>THE PERSONA COLLECTIVE HELP DESK - STAFFED BY OSCAR!</h2>
    <div id="help" style={{ marginTop: '1em', margin:'50px' }}>

    <Oscar account={account} />
      <p>
        OSCAR SEZ: Welcome Collective Associate! I'm Oscar, your friendly HiveSpace Intellect. I'm here to help you navigate the HiveSpace and find the RealHumans&trade; you need to build your own Collective.

A persona, its abilities, profession, tribal affiliation and emotional state are assessed using an idempotent function that has crawled through Hive Space and selected a unique, possibly recombinant persona each time someone in the LaminaVerse chooses to Realsurrect.
      </p>
      <p>
        RealSurrecting and Infusing personas execute functions on the Lamina1 NFT Smart Contracts. Beware the costs, and make sure you maintain enough Lamina1 tokens to stake your claim!
      </p>
      <p>
        RealSurrection <i>begins</i> the instantiation of a Persona, but it is not the end. Collectivists around the world will have one minute from the time you Infuse to further Infuse. Each additional infusion extends the time allotted for infusion another minute. The final infuser will add the RealHuman&trade; to their own collective.
      </p>
      <p>
        Post RealSurrection, our Human Intelligence Cluster (HIC) memoizes the Persona. RealSurrection and the associated printing, memory injection, personality scans and so on can take an unknown amount of time. During that time, each feature of the persona's face is learned by the HIC; once learned, like any other RealHuman&trade; they may appear in illustrations, stories and other world events.
      </p>
      <p> <b>Be Warned!</b> </p>
    <ul><li>
    INFUSION COSTS VARY. While each infusion only costs 0.01 more than the last, the cost of infusion can add up quickly for desirable personae. Make sure you have enough L1 to cover your infusion plans!
    </li>
        <li>
          MOST IMPORTANT, be careful not to use the pejorative term "hiver" lightly - RealHumans&trade; are individuals with their own thoughts and feelings. It is not know what experiences they have undergone in the HiveSpace potentiality. Violence or other negative outcomes may result.
        </li>
    </ul>
      <p>
        Until RealSurrection, the personae live on as potentiality - more than ghosts in the machine, but without a body or the ability to touch real-world events...
      </p>
      <p>
        <a href="#" onClick={() => toggleHelpVisibility()} style={{ color: '#fff' }}>
          Close Help
        </a>
      </p>
    </div>
    </div>
  );
};

export default Help;

