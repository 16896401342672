import React, { useEffect, useState } from 'react';
//import Web3 from 'web3';
import axios from 'axios';
//import './Bioprinting.css';
import Help from './Help.js';
import useToggle from './useToggle';
import {Persona, isPersona } from './PersonaType';
import { Link } from 'react-router-dom';

import './HomePage.css';
import { EventLogProvider, EventLog, useEventLog } from './EventLog'; 

import './EventLog.css';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import PersonaPage from './PersonaPage';

import { BrowserProvider, FixedNumber, formatEther, JsonRpcProvider, Contract, parseEther } from 'ethers';

import { InfuseButton, RealSurrectButton, HarvestButton, TimeRemainingTicker } from './Buttons';

import ethers from 'ethers';

import Countdown from './Countdown';
import PersonaArtifact from './contracts/Persona.json';
import BloodArtifact from './contracts/Blood.json';
import L1Address from './L1Address';

const personaAbi = PersonaArtifact.abi;
const bloodAbi = BloodArtifact.abi;
const personaAddress ="0x39Ad0b6fa13E3a348CB04817902EbB8b218113cf";
//0x47EF4452838EcDc8F010FDD616C7ECFd9fbcd526"; //0x9fe46736679d2d9a65f0992f2272de9f3c7fa6e0";
const bloodAddress = 	"0xbB730DD2f014a713159B42e97f1E8Cc4F00fb15E";
//"0xB3a6288d3524Cf8A2E53c184190276fEE793E623"; //0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512";


//const provider = new BrowserProvider(window.ethereum);

type HomePageProps = {
  provider: BrowserProvider | null;
  persona: Contract | null;
  inject: Function;
  blood: Contract | null;
  account: string | null;
  personas: Persona[];
  photos: Persona[];
  blockNum: number;
  purify: Function;
  hive: Contract | null;
}

declare global {
  interface Window {
    ethereum: any;
  }
}

type AttributeType = {
  trait_type: string;
  value: string | number;
  display_type?: string;
};

type PhotoCardProps = {
  photo: Persona;
};

const PhotoCard: React.FC<PhotoCardProps> = ({ photo }) => {
  return (
    <div className="photo-card">
      <p>ID: {photo.id}</p>
      <p>Name: {photo.name}</p>
      <p>Description: {photo.description}</p>
      <p>Profession: {photo.attributes?.profession?.value}</p>
      <p>Tribe: {photo.attributes?.tribe?.value}</p>
      <p>Strength: {photo.attributes?.strength?.value}</p>
      <p>Intelligence: {photo.attributes?.intelligence?.value}</p>
      <p>Charisma: {photo.attributes?.charisma?.value}</p>
      <p>Luck: {photo.attributes?.luck?.value}</p>
      <p>Pronoun: {photo.attributes?.pronoun?.value}</p>
      <p>Expression: {photo.attributes?.expression?.value}</p>
    </div>
  );
};


const HomePage: React.FC<HomePageProps> = ({ provider, persona, inject, blood, account, personas, photos, blockNum, purify, hive}) => {
  const [owned, setOwned] = useState<Persona[]>([]);
  const [isHelpVisible, toggleHelpVisibility] = useToggle(false);
  const [images, setImages] = useState(false);
  const [showCard, setShowCard] = useState<{ photo: Persona; x: number; y: number } | null>(null);
  const [ethBalance, setEthBalance] = useState<string | null>(null);
  const [bloodBalance, setBloodBalance] = useState<React.ReactNode| null>(null);
  const [randomSeed, setRandomSeed] = useState<number>(0);
  const [remainingPersonaCount, setRemainingPersonaCount] = useState<number>(2049);
  const [harvestedCt, setHarvested] = useState<number>(0);
  const [purifiedCt, setPurified] = useState<number>(0);
  /*
  useEffect(() => {
    console.log('Personas have changed:', personas);
  }, [personas]);
   */

  useEffect(() => {
    setRandomSeed(Math.floor(Math.random()*100));
  }, []);

  //Update the gallery on a new Blocknum
  useEffect(() => {
    // update from nfts, no need to hit the server
    let tempOwned: Persona[] = [];
    personas.forEach(persona => {
       if (!persona || !persona.sponsor) return; 
      if (persona.sponsor != null && persona.sponsor != account || persona.woken == false ) { return; }
      tempOwned.push(persona);
    });

    tempOwned.sort((a, b) => a.wakeDate - b.wakeDate);
    setOwned(tempOwned)
  }, [account, blockNum, personas]);


  // Update the header as to how many personas are left to be woken

  useEffect(() => {
    if (persona) {
      // go through the personas struct and count how many have sponsor == null
      let ct: number = 0;
      let hCt: number = 0;
      let pCt: number = 0;
      for (let i = 0; i < personas.length; i++) {
        if (personas[i].sponsor == "0x0000000000000000000000000000000000000000") {
          ct++
        }
        if (personas[i].harvested) { hCt++ }
        if (personas[i].purified) { pCt++ }
      }
      setRemainingPersonaCount(ct);
      setHarvested(hCt);
      setPurified(pCt);
    }}, [personas]);

  const handleMouseEnter = (e: React.MouseEvent<HTMLImageElement>, photo: any) => {
    // Determine the position for the popup based on mouse position
    const photoPosition = e.currentTarget.getBoundingClientRect();

    // ... Rest of your logic to determine if left or right
    // Instead of manipulating DOM directly, update the state
    setShowCard({
      photo: photo,
      x: (photoPosition.left + photoPosition.width / 2 > window.innerWidth / 2) 
      ? photoPosition.left - 600 - 10 
      : photoPosition.right + 10,
      y: photoPosition.top
    });
  };

  useEffect(() => {
    const card = document.querySelector('.photo-card') as HTMLElement;
    if (card && showCard) {
      card.style.left = `${showCard.x}px`;
      card.style.top = `${showCard.y}px`;
    }
  }, [showCard]);


  /*
  const handleMouseEnter = (e: React.MouseEvent<HTMLImageElement>, photo: any) => {
  const card = document.querySelector('.photo-card') as HTMLElement;
    console.log("Here", card);
  if (!card) return;

  const target = e.target as HTMLImageElement;
  const photoPosition = target.getBoundingClientRect() as DOMRect;


    console.log("Got a card", card);
  const cardWidth = card.offsetWidth;

  if (photoPosition.left + photoPosition.width / 2 > window.innerWidth / 2) {
    // Show card to the left of the photo
    card.style.left = `${photoPosition.left - cardWidth - 10}px`; // 10px for spacing
  } else {
    // Show card to the right of the photo
    card.style.left = `${photoPosition.right + 10}px`; // 10px for spacing
  }

  card.style.top = `${photoPosition.top}px`;
  // ... Rest of your logic
};

   */
  /*
  const handleMouseEnter = (e: React.MouseEvent, photo: PhotoType) => {
    const { clientX, clientY } = e;
    setShowCard({ photo, x: clientX, y: clientY });
  };
   */
  const [logs, setLogs] = useState<string[]>([]);

  const log = (message: string) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  const abbreviateAddress = (address: string | null) => {
    // Return the address with the middle part replaced by '...'
    return address ? ( <span title={address}>
      {address.slice(2, 6)}&hellip;{address.slice(-4)}
      </span>) : (<span>Connect an L1 wallet</span>);
  };



  // The most recent and best wakedate will be in the personas structure. use the photo Id to ref the persona in personas and get the right wakedate
  const photoTimer = (photo: Persona) => {
    const persona = personas[Number(photo.id)];
    if (persona == null) { return photo.wakeDate; }
    return persona.wakeDate;
  };

  const renderPhotos = () => {
    const renderedPhotos = photos.map((photo) => (
      <div key={`infusion-${photo.id}`} className="photo-container">
        The {photo.attributes?.profession.value} <br />
        <Link to={`/persona/${photo.id}`}><img 
        onMouseEnter={(e) => handleMouseEnter(e, photo)}
      onMouseLeave={() => setShowCard(null)}
      src={`/api/${photo.id}.png`} 
        alt={photo.name} 
      /></Link>
    <TimeRemainingTicker persona={photo} />
    <div className="info_sub" style={{ width: '90%' }}>Sponsor: {account && photo.sponsor.toLowerCase() == account.toLowerCase() ? "You" : <L1Address address={photo.sponsor} blockNum={blockNum} abbreviate={true} /> }</div>
    <InfuseButton persona={photo} inject={inject} blockNum={blockNum}/>
    <RealSurrectButton persona={photo} finalize={finalize} />
    </div>
    ));

    const missingPhotosCount = renderedPhotos.length - renderedPhotos.length;

    for (let i = 0; i < missingPhotosCount; i++) {
      const qid = ((randomSeed + i) % 8) + 1;
      renderedPhotos.push(
        <div key={`missing-${i}`} className="photo-container">
          Search.. <br />
          <img src={`/q${qid}.png`}  alt="Search The Hive" />
          { persona != null  ? (
          <button onClick={async () => {

            await hive!.searchHive({ value: parseEther('0.02')} );

          }}>
          Search The Hive
          </button>) : "" }
        </div>
      );
    }
const groupedPhotos = [];
  for (let i = 0; i < renderedPhotos.length; i += 5) {
    groupedPhotos.push(renderedPhotos.slice(i, i + 5));
  }

  // Wrap each group of 5 in a new div
  const rows = groupedPhotos.map((group, index) => (
    <div key={`row-${index}`} className="photo-row">
      {group}
    </div>
  ));

    return <div>{rows}</div>;

  };

  const loadOwned = async () => {
    if (!account) {
      console.log("No account, can't load owned");
      return;
    }

    const response = await axios.get(`/owned/${account}`);
    setOwned(response.data);
  };

  const AppContent = () => {
    const { addEvent } = useEventLog();

    useEffect(() => {
      addEvent('HomePage Loaded' + Date.now());
    }, []);

    return (
      <EventLog />
    );
  };

  const finalize = async(photoId: string) => {
    try {
      await hive?.finalizeInfusion(photoId);
    } catch(error: any) {
      console.error(`Error finalizing Infusion and RealSurrecting: ${error.reason}`);
    }
  };


  /*{ !window.ethereum  && (
  <h1>Please connect a Lamina1-capable wallet to this site to continue.</h1>
  )}
  { window.ethereum && (
   <L1Account updateAccount={setAccount} setImages={setImages} /> 
  )}
   */
  return (
    <EventLogProvider>
      <div>
        <h1>Persona Collective: RealSurrection
        <a href="#help" onClick={toggleHelpVisibility} style={{ color: '#fff',  fontSize: '0.5em', 
            cursor: 'pointer', 
            verticalAlign: 'top' 
        }}>
        ?
      </a>
    </h1>
    { (window.ethereum ?  <h2 className="centered">RealSurrected: { 2049 - remainingPersonaCount } HiveSpace: { remainingPersonaCount } Harvested: { harvestedCt } </h2> : "" ) }
    {isHelpVisible && (
    <Help account isVisible={isHelpVisible} toggleHelpVisibility={toggleHelpVisibility} />
    )}
      <div>
      {renderPhotos()}
    </div>
    <div className="card">
      {
      showCard?.photo && 
      (
      <div >
        <PhotoCard photo={showCard.photo} />
      </div>
      )
      }
    </div>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 2, marginRight: '10px' }}>
        <h2>Your Collective</h2>
        <div className="gallery">
          {owned.map((photo: any) => (
          <span key={`owned-gallery-${photo.id}`} className="gallery-photo-container">
            <Link to={`/persona/${photo.id}`}><img width="128" height="128" src={`/api/${photo.id}.png?size=128`} className="greyscale128" alt={photo.name} /></Link>
          </span>
          ))}
        </div>
      </div>
    </div>
  </div>
</EventLogProvider>

  );
};
export default HomePage;
