import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BrowserProvider, FixedNumber, formatEther, JsonRpcProvider, Contract, parseEther } from 'ethers';

import { Link } from 'react-router-dom';
import { InfuseButton, RealSurrectButton, HarvestButton, TimeRemainingTicker } from './Buttons';
import './Others.css';
import  L1Address  from './L1Address';

import { Persona } from './PersonaType';
type OthersPageProps = {
  personas: Persona[];
  blockNum: number;
};

type OwnerMap = Record<string, {totalInfused: number, personaIds: number[]}>;

export const OthersPage: React.FC<OthersPageProps> = ({personas, blockNum}) => {
  // const { id } = useParams<{ id: string }>();  -- could use this for address? Gallery could take an address, actually

  const [ownerList, setOwnerList] = useState<OwnerMap | null>(null);

  useEffect(() => {
    const tempOwnerList: OwnerMap = {};
    if (!personas) { return; }
    personas.forEach(persona => {
      // check if persona is nil
      if (!persona) { return; }
      if (persona.sponsor == "0x0000000000000000000000000000000000000000") { return; }
      if (persona.woken == false) { return; }
      if (persona.collector in tempOwnerList) {
        // If sponsor already exists, add to their persona list and sum totalInfused
        tempOwnerList[persona.collector].personaIds.push(persona.id);
        tempOwnerList[persona.collector].totalInfused += persona.totalInfused;
      } else {
        // If collector doesn't exist yet, create a new entry
        tempOwnerList[persona.collector] = {
          totalInfused: persona.totalInfused,
          personaIds: [persona.id]
        };
      }
    });

    Object.keys(tempOwnerList).forEach((ownerAddress) => {
      tempOwnerList[ownerAddress].personaIds.sort((a, b) => personas[a].wakeDate - personas[b].wakeDate);
    });
    setOwnerList(tempOwnerList);
  }, [personas]);


  return (
    <div>
    <h1>Collectives</h1>
      {ownerList && Object.entries(ownerList).map(([address, summary]) => (
      <div key={address+"-collective"} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <div style={{ marginRight: '16px' }}>
          <div><L1Address address={address} blockNum={blockNum} abbreviate={true} /></div>
          <div>Infusion Power: 
          { parseFloat(formatEther(BigInt(summary.totalInfused))).toFixed(3) }
        </div>
      </div>
      <div style={{ display: 'flex' , flexDirection:'row', width:'80%', flexWrap:'wrap' }}>
        {summary.personaIds.map(id => (
        <Link to={`/persona/${id}`}>
          <img 
          src={`/api/${id}.png?size=64`} 
          alt={`Persona ${id}`} 
          className="greyscale"
        />
          </Link>
          ))}
        </div>
      </div>
      ))}
    </div>
  );

};

export default OthersPage; 
