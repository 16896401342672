import { ethers } from 'ethers';
import { Buffer } from 'buffer';
import { namehash } from 'ethers';
//import nameHash from '@ensdomains/eth-ens-namehash';
import * as nameHash from '@ensdomains/eth-ens-namehash';
import abi from './PublicResolver.json';


const ID_PROVIDER = "https://rpc-betanet.lamina1.com:443/ext/bc/2CPW4Bj4YGPuchkzo3tNgryL2JNUGDxpHQtRLUznQajnY96g5A/rpc";
const ID_RESOLVER = "0xDcFEc218bAeD9f7C5c370a83c921A4Dd690B1B3C"; // PublicResolver.sol contract


export async function testL1ns() {
    try {
        const provider = new ethers.JsonRpcProvider(ID_PROVIDER); // Public RPC for ID Subnet
        const resolver = new ethers.Contract(ID_RESOLVER, abi.abi, provider);
        
        // Get address to lookup
        const testAddr = "0x66A4461d98E29e89c1633f3467CcFB9cfC146D19";
        console.log("Address to lookup:\t\t ", testAddr);
        
        // Normalize for hash
        const normalizedAddr = nameHash.normalize(testAddr.substring(2) + ".addr.reverse");
        console.log("Normalized address string:\t ", normalizedAddr);
        
        // Hash into lookup node
        //const l1nsNode = await namehash.hash(normalizedAddr);
        const l1nsNode = namehash(normalizedAddr);
        console.log("L1NS reverse node for address:\t ", l1nsNode);
       
        // Search for node (returns username if it exists)
        const l1nsName = await resolver.name(l1nsNode);
        console.log(`Address ${testAddr} = ${l1nsName}`);
    } catch (error) {
        console.error("Error - ", error);
    }
}

(async () => {
    await testL1ns();
})();
