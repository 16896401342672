
import React, { useEffect, useState } from 'react';

import { BrowserProvider, FixedNumber, formatEther, JsonRpcProvider, Contract, parseEther } from 'ethers';
import { Persona } from './PersonaType';
import Countdown from './Countdown';

interface InfuseButtonProps {
  persona: Persona;
  inject: Function;
  blockNum: number;
}

export const InfuseButton: React.FC<InfuseButtonProps> = ({ persona, inject , blockNum}) => {
  if (persona.wakeDate * 1000 <= Date.now()) { return null;}
  if (persona.harvested) { return null;}


  const infusionAmount = BigInt(persona.lastInfusion) + BigInt(parseEther("1"));
  return (
    <button onClick={() => inject(persona.id.toString(), infusionAmount)}>
      Infuse {parseFloat(formatEther(infusionAmount)).toFixed(2)} L1
    </button>
  );
};

interface RealSurrectButtonProps {
  persona: Persona;
  finalize: Function;
}

export const RealSurrectButton: React.FC<RealSurrectButtonProps> = ({ persona, finalize }) => {
  if (persona.wakeDate * 1000 > Date.now()) return null;
  if (persona.woken == true ) return null;
  if (persona.wakeDate == 0) return null;
  if (persona.harvested) return null;
  if (persona.realsurrected) return null; // this might be superfluous? Nah it's not.

  return(
  <button onClick={() => finalize(persona.id.toString())}>
    RealSurrect
  </button>);
};

interface HarvestButtonProps {
  persona: Persona;
  account: string;
  harvest: Function;
  bloodBalance: number|null;
}

export const HarvestButton: React.FC<HarvestButtonProps> = ({ persona, account, harvest, bloodBalance }) => {
  if (persona.sponsor !== account || !persona.woken || persona.harvested || !persona.alive || !bloodBalance || bloodBalance < 200) {
    return null;
  }
  
  return (
    <div className="harvest-container">
      <button className="harvest-button" onClick={() => harvest(persona.id)}>Harvest</button>
      <div className="harvest-warning">
        <img src="/oscar4.png" width="128" height="128" />
        <div>
          Oscar sez: Warning, Persona Harvesting is a controversial practice in and out of HiveSpace. No current technology exists to reverse a Harvesting ceremony. The BLOOD Bank no longer provides harvesting services. Blackmarket Infusion Recovery Ratio: 10%
            </div>
      </div>
    </div>
  );
};

interface TimeRemainingTickerProps {
  persona: Persona;
}

export const TimeRemainingTicker: React.FC<TimeRemainingTickerProps> = ({ persona }) => {
  if (persona.wakeDate * 1000 <= Date.now()) return null;
  return(
  <div className="info_sub" style={{ width: '90%' }}>
    Time Remaining: <Countdown endTime={persona.wakeDate} />
  </div>);
};

interface PurifyProps {
  persona: Persona;
  account: string;
  purify: Function;
}

export const PurifyButton: React.FC<PurifyProps> = ({ persona, account, purify }) => {
  if (!persona.woken || !persona.alive || persona.harvested || persona.purified) return null;
  if (persona.sponsor !== account) return null;

  return (
    <button className="purify-button" onClick={() => purify(persona.id)}>Purify</button>
  );

};

interface NameProps {
  persona: Persona;
  account: string;
  collectivistName: Function;
}

export const NameButton: React.FC<NameProps> = ({ persona, account, collectivistName }) => {
  if (!persona.woken || !persona.alive || persona.harvested || persona.purified) return null;
  if (persona.sponsor != account) return null;

  // return a name form, a warning about naming, and a button to submit the name which calculates out the price. 
  // price is 3% rounded up of infusion level. 1% if purified. 
  const price = Math.ceil((persona.lastInfusion * 3) / 100);
  const pricePurified = Math.ceil((persona.lastInfusion * 1) / 100);

  return (
    <div className="name-container">
      <div className="name-form">
        <input type="text" id="name" name="name" placeholder="Name" />
        (persona.purified ? <button onClick={() => collectivistName(persona.id, pricePurified)}>Name for {pricePurified} L1</button> : <button onClick={() => collectivistName(persona.id, price)}>Name for {price} L1</button>)
      </div>
      <div className="name-warning">
        <img src="/oscar4.png" width="128" height="128" />
        <div>
          Oscar sez: Hive Authorities monitor naming carefully. Names must not be likely to receive an "R" or "NC-17" rating from the Hive Authorities automated monitors. Personas with names breaching Hive City rules will be renamed, and lose ALL infusion levels. There are no appeals; renaming is solely controlled by Hive City autonomous agents, as is assessment of name suitability. 
        </div>
      </div>
    </div>
  );
}

interface AddToWalletButtonProps {
  tokenId: string;
  nftAddress: string;
}

export const addToWallet = (tokenId: string,nftAddress: string) => {
  console.log(tokenId, nftAddress, "added to wallet");
  if (window.ethereum) {
    window.ethereum
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC721',
          options: {
            address: nftAddress,
            symbol: 'Persona',
            tokenId: tokenId,
            image: `https://personacollective.ai/api/${tokenId}.png`,
          },
        },
      })
      .then((success: boolean) => {
        if (success) {
          console.log('added to wallet!');
        } else {
          throw new Error('Something went wrong.');
        }
      })
      .catch(console.error);
  } else {
    console.error('Ethereum provider is not available');
  }
};

export const AddToWalletButton: React.FC<AddToWalletButtonProps> = ({ tokenId, nftAddress}) => {
  return (
    <button onClick={() => addToWallet(tokenId, nftAddress)}>Add to Wallet</button>
  );
};

