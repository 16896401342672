import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface EventLogContextType {
  events: string[];
  addEvent: (event: string) => void;
}

const EventLogContext = createContext<EventLogContextType | undefined>(undefined);

interface EventLogProviderProps {
  children: ReactNode;
}

export const useEventLog = () => {
  const context = useContext(EventLogContext);
  if (!context) {
    throw new Error("useEventLog must be used within an EventLogProvider");
  }
  return context;
};

export const EventLogProvider: React.FC<EventLogProviderProps> = ({ children }) => {

  const [events, setEvents] = useState<string[]>([]);

  const addEvent = (event: string) => {
    setEvents((prevEvents) => [event, ...prevEvents]);
  };

  return (
    <EventLogContext.Provider value={{ events, addEvent }}>
      {children}
    </EventLogContext.Provider>
  );
};

export const EventLog: React.FC = () => {
  const { events } = useEventLog();

  return (
    <div className="event-log">
      {events.map((event, index) => (
      <div key={index}>{event}</div>
      ))}
    </div>
  );
};

