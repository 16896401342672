
export interface Persona {
  id: number,
    description: string,
    external_url: string,
    image: string,
    name: string,
    wakeDate: number,
    sponsor: string,
    collector: string,
    lastInfusion: number,
    totalInfused: number,
    harvested: boolean,
    realsurrected: boolean,
    purified: boolean,
    woken: boolean,
    alive: boolean,
    attributes: Record<string, any>,
};

export function isPersona(object: any): object is Persona {
  return (
    typeof object.id === 'number' &&
    typeof object.description === 'string' &&
    typeof object.external_url === 'string' &&
    typeof object.image === 'string' &&
    typeof object.name === 'string' &&
    typeof object.wakeDate === 'number' &&
    typeof object.sponsor === 'string' &&
    typeof object.lastInfusion === 'number' &&
    typeof object.totalInfused === 'number' &&
    typeof object.harvested === 'boolean' &&
    typeof object.attributes === 'object' && 
    object.attributes !== null // Checking that attributes is not null
  );
};
